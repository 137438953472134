import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BgImage } from 'gbimage-bridge'

// find images in the homeHeroImage directory
const imageQuery = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "homeHeroImage" }
        publicURL: { regex: "/CedarLogLookout/" }
        ext: {}
        id: {}
        gid: {}
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

// getGatsbyImageData
// Given data from graphql query, choose random image from results
function chooseImageFromData(data) {
  try {
    const candidateImageData = data.allFile.edges.map(
      (x) => x.node.childImageSharp.gatsbyImageData
    )
    const imageIndex = Math.floor(Math.random() * candidateImageData.length)
    return candidateImageData[imageIndex]
  } catch {
    return '' // or default image
  }
}

const HeroImage = () => {
  const data = useStaticQuery(imageQuery)
  const pluginImage = chooseImageFromData(data)
  return <BgImage image={pluginImage} className="hero-image" />
}
export default HeroImage
