import React from 'react'
import Layout from '../components/layout/Layout'
import HeroImage from '../components/home/HeroImage'
import Regenerate from '../components/home/Regenerate'
import Diversify from '../components/home/Diversify'
import CurrentHighlights from '../components/home/CurrentHighlights'
import { Link } from 'gatsby'

const Index = () => {
  return (
    <Layout isHomePage={true}>
      <div id="home" className="page-content">
        <HeroImage />
        <Regenerate />
        <Diversify />
        <CurrentHighlights />

        <section id="more">
          <div className="inner">
            <h1>Big Dreams</h1>
            <p>We have big dreams and are just getting started....</p>
            <Link to="/about" className="button special">
              Find out more about us here
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Index
