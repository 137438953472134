import React from 'react'
import { Link } from 'gatsby'

class Regenerate extends React.Component {
	state = {
		opacity: 1,
	}

	calcOpacity = (lowY, highY, currentY, fadeIn) => {
		let deltaY = highY - lowY
		if (currentY < lowY || deltaY <= 0) {
			return fadeIn ? 0 : 1
		} else if (currentY > highY) {
			return fadeIn ? 1 : 0
		} else {
			let percent = (currentY - lowY) / deltaY
			return fadeIn ? percent : 1 - percent
		}
	}

	listenScrollEvent = (e) => {
		return this.setState({
			opacity: this.calcOpacity(700, 1000, window.scrollY, true),
		})
	}

	componentDidMount() {
		window.addEventListener('scroll', this.listenScrollEvent)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.listenScrollEvent)
	}

	// style={{ opacity: `${this.state.opacity}` }}

	render() {
		return (
			<section id="regenerate">
				<div className="inner">
					<h1>Regenerative Landscape and Watershed Stewards</h1>
					<p>
						This land found us! &nbsp;It spoke to us with abundant diversity and
						beauty, and we listened. &nbsp;In that moment our lives changed:
						&nbsp;we became landscape and watershed stewards! &nbsp;It is our
						dream and core mission to regenerate this land and supporting
						ecosystems back to vibrant health.
					</p>
					<Link to="/regenerate" className="button">
						Regenerate
					</Link>
				</div>
			</section>
		)
	}
}

export default Regenerate
