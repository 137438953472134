import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

// import picForest from '../../assets/images/diversify/forest800x600.png'
// import picGoat from '../../assets/images/diversify/goatsPhotoCreditDaynPullen800x410.jpg'
// import picBike from '../../assets/images/diversify/mtbike800x400.png'

const maxPictureWidth = 500

const pictureData = [
	{
		title: 'Landscape and Forestry',
		link: '/diversify/#landscape-and-forestry',
		img: (
			<StaticImage
				src="../../assets/images/diversify/NorthCedarGulchRidge600x450.jpg"
				alt="Landscape and Forestry"
				width={maxPictureWidth}
				layout="constrained"
				placeholder="blurred"
			/>
		),
	},
	{
		title: 'Ranching',
		link: '/diversify/#ranching',
		img: (
			<StaticImage
				src="../../assets/images/diversify/Cows_20230606_1600x450.jpg"
				alt="Ranching"
				width={maxPictureWidth}
				layout="constrained"
				placeholder="blurred"
			/>
		),
	},
	{
		title: 'Guest Experiences',
		link: '/diversify/#guest-experiences',
		img: (
			<StaticImage
				src="../../assets/images/diversify/mtbike600x424.png"
				alt="Guest Experiences"
				width={maxPictureWidth}
				layout="constrained"
				placeholder="blurred"
			/>
		),
	},
]

const Diversify = () => {
	return (
		<section id="diversify">
			<div className="inner">
				<div id="row1">
					<h1 class="item">Diversify</h1>
					<div class="item">
						<p>
							To sustainably support our mission, we need diversified holistic
							solutions that compliment the strengths and renewable resources of
							the land.
						</p>
						<p>And boy do we have a lot of ideas...</p>
						<a href="/diversify" className="button">
							Learn More
						</a>
					</div>
				</div>

				<div id="row2">
					{pictureData.map((card, index) => (
						<div className="card" key={index} style={{ display: 'grid' }}>
							<Link
								to={card.link}
								style={{
									gridArea: '1/1',
									// You can set a maximum height for the image, if you wish.
									// maxHeight: 600,
								}}
								layout="fullWidth"
							>
								{card.img}
							</Link>

							<div
								style={{
									// By using the same grid area for both, they are stacked on top of each other
									gridArea: '1/1',
									position: 'relative',
									// This centers the other elements inside the hero component
									placeItems: 'end start',
									display: 'grid',
									padding: '0 0 0 20px',
									pointerEvents: 'none',
								}}
							>
								{/* Any content here will be centered in the component */}
								<h2>{card.title}</h2>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Diversify

// <h3>{card.title}</h3>

// <div id="row2">
// <div style={{ display: 'grid' }}>
// 	{/* You can use a GatsbyImage component if the image is dynamic */}
// 	<StaticImage
// 		style={{
// 			gridArea: '1/1',
// 			// You can set a maximum height for the image, if you wish.
// 			// maxHeight: 600,
// 		}}
// 		layout="fullWidth"
// 		// You can optionally force an aspect ratio for the generated image
// 		aspectRatio={3 / 1}
// 		// This is a presentational image, so the alt should be an empty string
// 		alt=""
// 		// Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
// 		src={
// 			'https://images.unsplash.com/photo-1604975999044-188783d54fb3?w=2589'
// 		}
// 		formats={['auto', 'webp', 'avif']}
// 	/>
// 	<div
// 		style={{
// 			// By using the same grid area for both, they are stacked on top of each other
// 			gridArea: '1/1',
// 			position: 'relative',
// 			// This centers the other elements inside the hero component
// 			placeItems: 'center',
// 			display: 'grid',
// 		}}
// 	>
// 		{/* Any content here will be centered in the component */}
// 		<h1>Hero text</h1>
// 	</div>
// </div>

// 		<div style={{ backgroundImage: `url(${card.picture})` }}>
// 			<Link
// 				aria-label={card.title}
// 				to={card.link}
// 				className="link primary"
// 			>
// 				<header className="major">
// 					<h3>{card.title}</h3>
// 				</header>
// 			</Link>
// 		</div>
// 	))}

// </div>

// 	{pictureData.map((card, index) => (
// 		<div style={{ backgroundImage: `url(${card.picture})` }}>
// 			<Link
// 				aria-label={card.title}
// 				to={card.link}
// 				className="link primary"
// 			>
// 				<header className="major">
// 					<h3>{card.title}</h3>
// 				</header>
// 			</Link>
// 		</div>

// <div>
// 	{pictureData.map((tile, index) => (
// 		<div>
// 			<Row height={100}>
// 				<Col md={4} width={pxWidth}>
// 					<p>{tile.text}</p>
// 					<Image
// 						className="tile-image"
// 						src={tile.imageSource}
// 						alt="logo"
// 					/>
// 				</Col>
// 			</Row>
// 		</div>
// 	))}
// </div>

// <br />
// <br />
// <br />
